<template>
  <div id="transfers_details" class="collapse" data-parent="#services_accordion">
    <div class="card-body p-2">
      <div class="add_button_container">
        <button @click.prevent="is_add_model = true" class="btn btn-info">Add Transfer</button>
      </div>
      <hr class="my-2" />
      <table class="table">
        <thead class="table-teal">
          <tr>
            <th style="width: 40px"></th>
            <th style="width: 80px">Status</th>
            <th style="width: 135px">Type</th>
            <th>From</th>
            <th>To</th>
            <th>Description</th>
            <th style="width: 80px">Price</th>
            <th style="width: 120px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in packageTransfer" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <label class="badge" :class="[`badge-${item.is_include ? 'success' : 'warning'}`]">{{ item.is_include ? "Included" : "Optional" }}</label>
            </td>
            <td>{{ item.type }}</td>
            <td>{{ item.from }}</td>
            <td>{{ item.to }}</td>
            <td><div v-html="item.details"></div></td>
            <td>{{ item.price || "-" }}</td>
            <td>
              <div class="action-btn">
                <button class="btn btn-info mr-2" @click.prevent="onEditProduct(item, index)">Edit</button>
                <button @click.prevent="delete_item = item" class="btn btn-danger">Delete</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <popup-model class="transfers_model" :close-btn="false" :openModel="is_add_model">
      <template #content>
        <div class="title">{{ is_update ? "Update" : "Add " }} Transfer</div>
        <hr class="mt-2 mb-0" />
        <div class="form_container">
          <div class="row gutter14">
            <div class="col-md-6">
              <div class="form-group" :class="{ invalid: $v.formData.from.$error }">
                <label class="required">From</label>
                <input type="text" @blur.prevent="$v.formData.from.$touch()" v-model.trim="formData.from" class="form-control" placeholder="Location Name" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" :class="{ invalid: $v.formData.to.$error }">
                <label class="required">To</label>
                <input type="text" @blur.prevent="$v.formData.to.$touch()" v-model.trim="formData.to" class="form-control" placeholder="Location Name" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Type</label>
                <select v-model.trim="formData.type" class="form-control">
                  <option value>Choose</option>
                  <option>Private Basis</option>
                  <option>Seat in Coach Basis</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Price</label>
                <input type="text" v-input-type="'number'" placeholder="Amount" v-model.trim.number="formData.price" class="form-control" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group mb-0">
                <label>Details</label>
                <vue-editor :editor-toolbar="toolbarSettings" v-model.trim="formData.details" />
              </div>
            </div>
          </div>
        </div>
        <hr class="my-2" />
        <div class="action_container">
          <div class="form-group mr-3 mb-0">
            <label class="checkbox" style="font-weight: 600"><input v-model="formData.is_include" type="checkbox" class="mr-2" /> Mark Include</label>
          </div>
          <button @click.prevent="is_add_model = false" class="btn btn-danger mr-2">Cancel</button>
          <button @click.prevent="onSubmitForm" class="btn btn-info">{{ is_update ? "Update" : "Submit" }}</button>
        </div>
      </template>
    </popup-model>
    <popup-model class="delete_model" :close-btn="false" :openModel="delete_item != null">
      <template #content>
        <div class="title">Delete Item</div>
        <hr class="my-2" />
        are you sure you want to delete <span v-if="delete_item" class="fnt-13 clr-teal font-bold">{{ delete_item.from }} - {{ delete_item.to }}</span> ?
        <hr class="my-2" />
        <div class="d-flex justify-content-end">
          <button @click.prevent="delete_item = null" class="btn btn-danger mr-2">Cancel</button>
          <button @click.prevent="onRemoveProduct" class="btn btn-info">Submit</button>
        </div>
      </template>
    </popup-model>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Transfer",
  computed: {
    ...mapGetters(["packageTransfer", "packageDetails"])
  },
  data() {
    return {
      is_add_model: false,
      formData: { from: "", to: "", type: "", price: "", details: "", is_include: false },
      is_update: false,
      selected_product: null,
      delete_item: null,
      toolbarSettings: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }],
        [{ script: "sub" }, { script: "super" }],
        [{ direction: "rtl" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["link", "clean"]
      ]
    };
  },
  methods: {
    ...mapActions(["updatePackage"]),
    ...mapMutations(["managePackageServices"]),
    /*   handleSaveData() {
      this.updatePackage({ type: "ADVANCE", data: { advance: { code: this.$route.query.code, data: { transfers: this.packageTransfer } } } }).then(() => {
        this.$toasted.success(`update Transfer Details`, { position: "bottom-center", duration: 5000 });
      });
    }, */
    onEditProduct(item, index) {
      this.selected_product = index;
      this.formData = JSON.parse(JSON.stringify(item));
      this.is_update = true;
      this.is_add_model = true;
    },
    resetForm() {
      this.is_add_model = false;
      this.formData = { from: "", to: "", type: "", price: "", details: "", is_include: false };
      this.is_update = false;
      this.selected_product = null;
      this.$v.$reset();
    },
    onSubmitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const _transfers = JSON.parse(JSON.stringify(this.packageTransfer));
        const _form_data = { ...this.formData };
        const _exists_index = _transfers.findIndex((val) => val.from?.toLowerCase() == this.formData.from?.toLowerCase() && val?.to?.toLowerCase() == this.formData.to?.toLowerCase());
        if (this.selected_product == null) {
          if (_exists_index != -1) this.$toasted.info("Transfer already exists...", { position: "bottom-center", duration: 3500 });
          else _transfers.push(_form_data);
        } else _transfers.splice(_exists_index, 1, _form_data);
        if (this.is_update || this.packageTransfer.length != _transfers.length) {
          this.updatePackage({ type: "ADVANCE", data: { advance: { code: this.$route.query.code, data: { transfers: _transfers } } } }).then(() => {
            this.managePackageServices({ service: "transfers", existsData: _transfers });
            this.resetForm();
            this.$toasted.success("Transfer Data Is Updated", { position: "bottom-center", duration: 3500 });
          });
        }
      }
    },
    onRemoveProduct() {
      let _transfers = JSON.parse(JSON.stringify(this.packageTransfer));
      const _exists_index = _transfers.findIndex((val) => val.from?.toLowerCase() == this.delete_item.from?.toLowerCase() && val?.to?.toLowerCase() == this.delete_item.to?.toLowerCase());
      _transfers.splice(_exists_index, 1);
      this.updatePackage({ type: "ADVANCE", data: { advance: { code: this.$route.query.code, data: { transfers: _transfers } } } }).then(() => {
        this.managePackageServices({ service: "transfers", existsData: _transfers });
        this.$toasted.success(`Transfers item removed...`, { position: "bottom-center", duration: 3500 });
        this.delete_item = null;
      });
    }
  },
  watch: {
    is_add_model(value) {
      if (!value) this.resetForm();
    }
  },
  validations: {
    formData: {
      from: { required },
      to: { required }
    }
  }
};
</script>

<style scoped>
.transfers_model >>> .popup_body {
  border-radius: 0;
  padding: 0;
  width: 650px;
  max-height: 600px;
}

.transfers_model .title {
  font-size: 14px;
  font-weight: 600;
  padding: 4px 10px 0px 10px;
}

.transfers_model >>> .form_container {
  padding: 4px 10px;
}

.transfers_model >>> .action_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 10px 10px 10px;
}

.transfers_model >>> .action_container button,
.delete_model >>> button {
  padding: 4px 10px;
  border-radius: 0;
  font-size: 0.8rem;
  width: 80px;
}

.transfers_model >>> .form_container .form-group label {
  font-weight: 600;
  font-size: 12px;
}

.delete_model >>> .popup_body {
  border-radius: 0;
  padding: 4px 10px;
  width: 300px;
}
.add_button_container {
  display: flex;
  justify-content: flex-end;
}

.add_button_container button {
  border-radius: 0;
  font-size: 0.8rem;
  padding: 3px 10px;
}

.action-btn button {
  border-radius: 0;
  height: 20px;
  line-height: 5px;
  font-size: 10px;
}

.form-group .checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}
</style>
