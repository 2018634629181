<template>
  <container headTitle="Manage Packages">
    <menu-item slot="createbtn" pageUrl="/package/create">
      <div class="text-right">
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-info"
          style="width: 100px"
          @click="isModelOpen = true"
          >Filter</a
        >
        <router-link
          class="btn btn-sm btn-info ml-2"
          style="width: 100px"
          to="/package/create?tabName=package-basic"
          >New Package</router-link
        >
      </div>
    </menu-item>

    <div slot="content" id="allpackages">
      <table class="table">
        <thead class="table-teal">
          <tr>
            <th style="max-width: 200px">Name</th>
            <th>Code</th>
            <th style="max-width: 200px">Dest Coverd</th>
            <th>Nights</th>
            <th>Booking(&#8377;)</th>
            <th>Type</th>
            <th>Status</th>
            <th>Ims</th>
            <th>Feature</th>
            <th>Created</th>
            <th>Last Updated</th>
            <th>Dates</th>
            <th style="width: 135px">Action</th>
          </tr>
        </thead>

        <tbody v-if="!packagesLoading && packagesList.length > 0">
          <list-items
            @package-publish="handlePackagePublish"
            v-for="item in packagesList.slice(list.min, list.max)"
            :item="item"
            :key="item._id"
          />
        </tbody>
      </table>

      <div
        v-if="packagesLoading"
        class="justify-content-center align-items-center d-flex"
        style="min-height: 200px"
      >
        <spinner />
      </div>

      <div
        v-if="!packagesLoading && packagesList.length == 0"
        class="justify-content-center align-items-center d-flex"
        style="min-height: 200px"
      >
        <h5>No Result Found</h5>
      </div>

      <popup-model :openModel="isModelOpen" @close-model="handleCloseModel">
        <div slot="content">
          <filter-list @onSearchButton="handleFilterButton" />
        </div>
      </popup-model>
    </div>
  </container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FilterList from "./filter_list";
import ListItems from "./list-items";
export default {
  name: "Listing",
  data() {
    return {
      list: {
        min: 0,
        max: 30
      },
      isModelOpen: false
    };
  },
  watch: {
    packagesList(value) {
      if (value.length > 0) {
        let element = document.querySelector(".container-content");
        element.addEventListener("scroll", this.renderMorePackages);
      }
    }
  },
  components: { FilterList, ListItems },
  created() {
    this.getAllPackages();
  },
  methods: {
    ...mapActions(["getAllPackages", "makePackagePublishRequest"]),
    handlePackagePublish({ code, value }) {
      const itemIndex = this.packagesList.findIndex(data => data.code == code);
      this.makePackagePublishRequest({ code, isPublishRequest: value }).then(
        data => {
          let message = `Package Code ${data.code}  Request ${
            value ? "Send" : "Cancelled"
          } Successfully`;
          if (data.message == "publish") {
            this.packagesList[itemIndex].isPublishRequest = false;
            this.packagesList[itemIndex].isPublished = true;
            message = `Package Code ${data.code} Published Successfuly`;
          } else {
            this.packagesList[itemIndex].isPublishRequest = value;
            this.packagesList[itemIndex].isPublished = false;
          }
          this.$toasted.success(message, {
            position: "bottom-center",
            duration: 5000
          });
        }
      );
    },
    renderMorePackages() {
      const element = document.querySelector(".container-content");
      if (
        element.scrollTop + element.clientHeight >=
        element.scrollHeight - 10
      ) {
        if (this.list.max < this.packagesList.length) {
          this.list.max += 25;
        }
      }
    },

    handleCloseModel() {
      this.isModelOpen = false;
    },
    handleFilterButton(data) {
      this.getAllPackages(data).then(() => {
        this.isModelOpen = false;
      });
    }
  },
  computed: {
    ...mapGetters(["packagesList", "packagesLoading", "user"])
  }
};
</script>

<style scoped>
.table-head >>> .popup_body {
  height: auto;
  max-width: 700px;
  overflow-y: initial !important;
  overflow-x: revert !important;
}
</style>
